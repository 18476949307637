import React from 'react';
import {
  StyledFooter,
  StyledWrapper,
  StyledHeader,
  StyledLink,
  StyledOffices,
  StyledTitle,
  StyledList,
  StyledItem,
  StyledAddress,
  StyledInfo,
  StyledSocial,
  StyledCopyright,
  StyledTel,
} from './styled';
import LinkedIn from './icons/Linkedin';
import logo from './icons/logo.svg';
import Nav from 'components/Layout/Header/Nav/Nav';

const CONTACTS = [
  {
    city: 'London',
    address: '6 Porter Street ',
    index: 'London W1U 6DD UK',
    phone: '+44 (0)20 7989 8800',
    country: 'UK',
  },
  {
    city: 'Milan',
    address: 'Via Santa Sofía 21,',
    index: '20122 Milano',
    phone: '(+39) 356 3456 7890',
    country: 'Italy',
  },
  {
    city: 'Mexico',
    address: 'Mexico city',
    index: '',
    phone: '(+52) 1 (555) 418-0741',
    country: 'Mexico',
  },
  {
    city: 'Tel Aviv',
    address: 'A.D. Gordon 17,',
    index: '6340723 Tel Aviv',
    phone: '(+972) 538 265 514',
    country: 'Israel',
  },
  {
    city: 'Madrid',
    address: 'Paseo de la Castellana 171',
    index: '3ºi 28046 Madrid',
    phone: '+34 91 123 0 567',
    country: 'Spain',
  },
];

const Footer = () => {
  return (
    <StyledFooter>
      <StyledWrapper>
        <StyledHeader>
          <StyledLink to="/">
            <img src={logo} width="183" height="40" alt="Logotype" />
          </StyledLink>
          <Nav color="#fff" />
        </StyledHeader>
        <StyledOffices>
          <StyledTitle>Presence in:</StyledTitle>
          <StyledList>
            {CONTACTS.map(contact => (
              <StyledItem key={contact.country}>
                {contact.city}
                <StyledAddress>
                  {contact.address}
                  <p>{contact.index}</p>
                  <StyledTel href={`tel:${contact.phone}`}>
                    {contact.phone}
                  </StyledTel>
                  <p>{contact.country}</p>
                </StyledAddress>
              </StyledItem>
            ))}
          </StyledList>
        </StyledOffices>
        <StyledInfo>
          <StyledSocial>
            Follow us on
            <a href="https://www.linkedin.com/company/demtech-sl" target="_blank">
              <LinkedIn />
            </a>
          </StyledSocial>
          <StyledCopyright>
            &copy; 2021 <span>Demtech</span>. All rights reserved<br/>
            Designed and developed by <a href="https://www.launch.ist">Launchist</a>
          </StyledCopyright>
        </StyledInfo>
      </StyledWrapper>
    </StyledFooter>
  );
};

export default Footer;
