import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Link from 'components/UI/Link/Link';

export const StyledHeader = styled.header`
  ${({ isHome }) => isHome && css`position: absolute;`};
  width: 100%;
  margin-bottom: 40px;
  z-index: 1;
`;

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 144rem;
  margin: 0 auto;
  padding: 2rem 1.7rem;

  ${MEDIA.TABLET`
    padding: 3.6rem 3rem;
  `}

  ${MEDIA.TABLET_XL`
    padding: 3.6rem 5rem;
  `}

  ${MEDIA.DESKTOP`
    padding: 3.6rem 12rem;
  `}

  ${MEDIA.DESKTOP_XL`
    padding: 3.6rem 16rem;
  `}
`;

export const StyledLogo = styled(Link)`
  img {
    display: block;
    width: 13.6rem;
    height: 1.6rem;

    ${MEDIA.TABLET_XL`
      width: 19.7rem;
      height: 2.4rem;
  `}
  }
`;

export const NavWrapper = styled.div`
  position: absolute;
  z-index: 20;
  width: 100%;
  min-height: 61.1rem;
  padding: 5.5rem 3.5rem 3.5rem;
  top: 5.6rem;
  left: 0;
  background-color: #181418;

  ${MEDIA.MAX_DESKTOP`
    display: ${props => (props.open ? 'block' : 'none')};
  `}

  ${MEDIA.TABLET`
    top: 9.6rem;
  `}

  ${MEDIA.DESKTOP`
    padding: 0;
    position: static;
    width: auto;
    min-height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
  `}
`;

export const StyledNavControl = styled.button`
  outline: none;

  ${MEDIA.MAX_DESKTOP`
    width: 2.4rem;
    height: 2.4rem;
    padding: 0;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `}

  ${MEDIA.TABLET`
    width: 4rem;
    height: 4rem;
  `}

  ${MEDIA.DESKTOP`
    display: none;
  `}
`;

export const StyledSvg = styled.svg`
  width: 2.2rem;
  height: 2.2rem;

  ${MEDIA.TABLET`
    width: 3rem;
    height: 3rem;
  `}
`;
