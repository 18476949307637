import React from 'react';

const Close = () => (
  <svg
    width="67"
    height="17"
    viewBox="0 0 67 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2.51367H2.56714V12.0644H7.31981V14.4845H0V2.51367Z"
      fill="white"
    />
    <path
      d="M8.46425 6.37441H10.9389V14.4848H8.46425V6.37441ZM9.69 2.33691C10.4763 2.33691 11.1239 2.98622 11.1239 3.8008C11.1239 4.60358 10.4879 5.26469 9.69 5.26469C8.90367 5.26469 8.2561 4.61539 8.2561 3.8008C8.2561 2.98622 8.90367 2.33691 9.69 2.33691Z"
      fill="white"
    />
    <path
      d="M21.2542 2.51367H23.7172V9.66784L26.5156 6.36228H29.5338L26.2959 10.1164L29.4759 14.4845H26.3653L23.7519 10.4706H23.7172V14.4845H21.2542V2.51367Z"
      fill="white"
    />
    <path
      d="M12.2921 6.37452H14.6626V7.48424H14.6973C15.0211 6.84675 15.8306 6.17383 17.0332 6.17383C19.5309 6.17383 19.9935 7.85022 19.9935 10.0342V14.4849H17.5189V10.5419C17.5189 9.59744 17.5073 8.39327 16.2353 8.39327C14.9517 8.39327 14.7551 9.42036 14.7551 10.471V14.4849H12.2805V6.37452H12.2921Z"
      fill="white"
    />
    <path
      d="M34.737 9.47973C34.7485 8.64154 34.1125 7.9332 33.2221 7.9332C32.1351 7.9332 31.5454 8.68876 31.476 9.47973H34.737ZM36.83 13.0804C36.0437 14.1075 34.737 14.6978 33.4534 14.6978C30.9903 14.6978 29.0129 13.0214 29.0129 10.4124C29.0129 7.80334 30.9903 6.12695 33.4534 6.12695C35.7546 6.12695 37.2116 7.80334 37.2116 10.4124V11.2033H31.4876C31.6841 12.1596 32.3895 12.7853 33.3493 12.7853C34.1588 12.7853 34.7023 12.3603 35.107 11.7936L36.83 13.0804Z"
      fill="white"
    />
    <path
      d="M42.3465 8.33381C41.1092 8.33381 40.3691 9.17201 40.3691 10.3998C40.3691 11.6276 41.1092 12.4658 42.3465 12.4658C43.5838 12.4658 44.3239 11.6276 44.3239 10.3998C44.3239 9.17201 43.5838 8.33381 42.3465 8.33381ZM46.5903 14.4845H44.3239V13.4102H44.2892C43.9076 14.0005 42.9593 14.6852 41.8377 14.6852C39.4671 14.6852 37.906 12.938 37.906 10.4706C37.906 8.20395 39.2936 6.11437 41.5601 6.11437C42.5777 6.11437 43.5375 6.3977 44.0926 7.18867H44.1273V2.51367H46.6019V14.4845H46.5903Z"
      fill="white"
    />
    <path
      d="M64.4793 0H50.2907C49.6084 0 49.0649 0.543055 49.0649 1.22778V15.7722C49.0649 16.4451 49.62 17 50.2907 17H64.4793C65.1616 17 65.7166 16.4451 65.7166 15.7722V1.22778C65.7166 0.543055 65.1616 0 64.4793 0ZM54.0026 14.4854H51.528V6.375H54.0026V14.4854ZM52.7653 5.26528C51.979 5.26528 51.3314 4.60417 51.3314 3.80139C51.3314 2.99861 51.9674 2.3375 52.7653 2.3375C53.5516 2.3375 54.1992 2.98681 54.1992 3.80139C54.1992 4.60417 53.5516 5.26528 52.7653 5.26528ZM63.2536 14.4854H60.7905V10.5424C60.7905 9.59792 60.7789 8.39375 59.5069 8.39375C58.2234 8.39375 58.0268 9.42083 58.0268 10.4715V14.4854H55.5637V6.375H57.9343V7.48472H57.969C58.2928 6.84722 59.1022 6.17431 60.3048 6.17431C62.791 6.17431 63.2536 7.85069 63.2536 10.0347V14.4854Z"
      fill="white"
    />
    <path
      d="M66.6301 14.166C66.7111 14.1542 66.7457 14.1069 66.7457 14.0361C66.7457 13.9417 66.6879 13.9062 66.5839 13.9062H66.4104V14.3785H66.4798V14.166H66.5607L66.6879 14.3667H66.7573L66.6301 14.166ZM66.5492 14.1187H66.4682V13.9535H66.5723C66.6185 13.9535 66.6764 13.9653 66.6764 14.0243C66.6879 14.1069 66.6301 14.1187 66.5492 14.1187Z"
      fill="white"
    />
    <path
      d="M66.5608 13.6943C66.3179 13.6943 66.1213 13.895 66.1213 14.1429C66.1213 14.3909 66.3179 14.5915 66.5608 14.5915C66.8036 14.5915 67.0002 14.3909 67.0002 14.1429C67.0002 13.8832 66.8152 13.6943 66.5608 13.6943ZM66.5608 14.5325C66.3526 14.5325 66.1792 14.3672 66.1792 14.1429C66.1792 13.9186 66.341 13.7534 66.5608 13.7534C66.7689 13.7534 66.9424 13.9186 66.9424 14.1429C66.9424 14.3672 66.7689 14.5325 66.5608 14.5325Z"
      fill="white"
    />
  </svg>
);

export default Close;
