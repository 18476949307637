import React from 'react';
import PropTypes from 'prop-types';
// import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/Head/head';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import GlobalStyle from 'global.css.js';
import { StyledLayout } from './styled';

const Layout = ({ children, page }) => (
  <StyledLayout>
    <Head />
    <GlobalStyle />
    <Header page={page} />
    {children}
    <Footer />
  </StyledLayout>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.string.isRequired,
};

/* const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
}; */

export default Layout;
