import React from 'react';
import { StyledList, StyledItem, StyledLink } from './styled';
import PropTypes from 'prop-types';

const HEADER_LINKS = [
  { title: 'Services', href: '/services', page: 'services' },
  { title: 'Ecosystem', href: '/clients', page: 'clients' },
  { title: 'News', href: '/news', page: 'news' },
  { title: 'Open Innovation', href: '/innovation', page: 'innovation' },
  { title: 'Sales', href: '/sales', page: 'sales' },
  { title: 'VC & Fund', href: '/funding', page: 'funding' },
  { title: 'Team', href: '/team', page: 'team' },
];

const Nav = ({ page, color }) => (
  <nav>
    <StyledList>
      {HEADER_LINKS.map(link => {
        const isActive = page === link.page;

        return (
          <StyledItem key={link.href} isActive={isActive} color={color}>
            <StyledLink to={link.href} color={color}>
              {link.title}
            </StyledLink>
          </StyledItem>
        );
      })}
    </StyledList>
  </nav>
);

Nav.propTypes = {
  page: PropTypes.string,
  color: PropTypes.string,
};

export default Nav;
