import styled from 'styled-components';
import Link from 'components/UI/Link/Link';
import MEDIA from 'helpers/mediaTemplates';

export const StyledFooter = styled.footer`
  position: relative;
  background-color: #181418;

  ${MEDIA.DESKTOP`
    &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 9.1rem;
    width: 100%;
    height: 0.1rem;
    background-color: #bdc6cf;
  }
  `}
`;

export const StyledWrapper = styled.div`
  max-width: 144rem;
  margin: 0 auto;
  padding: 0 1.7rem;

  ${MEDIA.TABLET`
    padding: 0 1.7rem;
  `}

  ${MEDIA.TABLET_XL`
    padding: 0 5rem;
  `}

  ${MEDIA.DESKTOP`
    padding: 0 12rem;
  `}

  ${MEDIA.DESKTOP_XL`
    padding: 0 16rem;
  `}
`;

export const StyledHeader = styled.section`
  display: none;

  ${MEDIA.DESKTOP`
    display: block;
    padding-top: 9.6rem;
    margin-bottom: 5.5rem;

    & ul {
      justify-content: center;
    }
  `}
`;

export const StyledLink = styled(Link)`
  display: block;
  width: 18.3rem;
  margin: 0 auto;
  margin-bottom: 5.5rem;
  color: #fff;
`;

export const StyledOffices = styled.section`
  display: none;

  ${MEDIA.DESKTOP`
    display: block;
    margin-bottom: 7.2rem;
  `}
`;

export const StyledTitle = styled.h3`
  margin-bottom: 3.2rem;
  font-weight: 700;
  line-height: 2.4rem;
  font-size: 1.8rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
`;

export const StyledList = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
`;

export const StyledItem = styled.li`
  width: 16.5rem;
  font-weight: 700;
  line-height: 2rem;
  font-size: 1.4rem;
  letter-spacing: 0.01rem;
  color: #ffffff;

  &:not(:last-child) {
    margin-right: 3.5rem;
  }
`;

export const StyledTel = styled.a`
  display: block;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
`;

export const StyledAddress = styled.span`
  display: block;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 0.8rem;
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 20.4rem;
  margin: 0 auto;
  font-weight: 400;
  line-height: 2.2rem;
  font-size: 1.6rem;

  ${MEDIA.DESKTOP`
    justify-content: space-between;
    min-height: 9.1rem;
    flex-direction: row;
  `}
`;

export const StyledSocial = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;
  color: rgba(255, 255, 255, 0.87);

  & svg,
  & a {
    line-height: 0;
  }

  & a {
    margin-left: 1rem;
  }

  ${MEDIA.DESKTOP`
    margin-bottom: 0;
  `}
`;

export const StyledCopyright = styled.p`
  color: #ffffff;

  & span {
    font-weight: 700;
  }

  & a {
    color: #ffffff;
  }

  ${MEDIA.DESKTOP`
    order: -1;
  `}
`;
