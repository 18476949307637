import React from 'react';
import { StyledSvg } from '../styled';

const Menu = ({ fill }) => (
  <StyledSvg width="18" height="18" viewBox="0 0 18 18">
    <g />
    <path
      d="M16 3v2h-15v-2h15zM1 10h15v-2h-15v2zM1 15h15v-2h-15v2z"
      fill={fill || '#fff'}
    />
  </StyledSvg>
);

export default Menu;
