import styled, { css } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Link from 'components/UI/Link/Link';

export const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  line-height: 2.2rem;
  font-size: 1.6rem;
  text-transform: uppercase;
  list-style: none;

  ${MEDIA.DESKTOP`
    flex-wrap: wrap;
    flex-direction: row;
    width: auto;
  `}
`;

export const StyledItem = styled.li`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 3.5rem;
  }

  ${({ isActive, isHome }) => isActive && css`
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 0.2rem;
      background-color: ${isHome ? '#fff' : '#181818'};
    }
  `}

  ${MEDIA.DESKTOP`
    &:not(:last-child) {
      margin-right: 2.5rem;
      margin-bottom: 0;
    }
  `}
`;

export const StyledLink = styled(Link)`
  display: block;
  position: relative;
  padding: 0.8rem 0.4rem;
  text-decoration: none;
  transition: color 0.1s linear;
  ${({ color }) => color && `color: ${color};`}

  &::before {
    content: '';
    position: absolute;
    width: 0;
    left: 0;
    bottom: 0;
    height: 0.2rem;
    transition: width 0.3s linear;
    ${({ color }) => color && ` background-color: ${color};`}
  }

  ${MEDIA.TABLET_XL`
    &:hover,
    &:focus {
      &::before {
        width: 100%;
      }
    }
  `}
`;
